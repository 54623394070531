@import '../../style/themes/index';
@import '../../style/mixins/index';

@carousel-prefix-cls: ~'@{ant-prefix}-carousel';
@carousel-dot-margin: 4px;

.@{carousel-prefix-cls} {
  .reset-component();

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
    }

    .slick-slide {
      pointer-events: none;

      // https://github.com/ant-design/ant-design/issues/23294
      input.@{ant-prefix}-radio-input,
      input.@{ant-prefix}-checkbox-input {
        visibility: hidden;
      }

      &.slick-active {
        pointer-events: auto;

        input.@{ant-prefix}-radio-input,
        input.@{ant-prefix}-checkbox-input {
          visibility: visible;
        }
      }

      // fix Carousel content height not match parent node
      // when children is empty node
      // https://github.com/ant-design/ant-design/issues/25878
      > div > div {
        vertical-align: bottom;
      }
    }
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    touch-action: pan-y;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;

    &::before,
    &::after {
      display: table;
      content: '';
    }

    &::after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    img {
      display: block;
    }

    &.slick-loading img {
      display: none;
    }

    &.dragging img {
      pointer-events: none;
    }
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  // Arrows
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
      color: transparent;
      background: transparent;
      outline: none;

      &::before {
        opacity: 1;
      }
    }

    &.slick-disabled::before {
      opacity: 0.25;
    }
  }

  .slick-prev {
    left: -25px;

    &::before {
      content: '←';
    }
  }

  .slick-next {
    right: -25px;

    &::before {
      content: '→';
    }
  }

  // Dots
  .slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex !important;
    justify-content: center;
    margin-right: 15%;
    margin-bottom: 0;
    margin-left: 15%;
    padding-left: 0;
    list-style: none;

    &-bottom {
      bottom: 12px;
    }

    &-top {
      top: 12px;
      bottom: auto;
    }

    li {
      position: relative;
      display: inline-block;
      flex: 0 1 auto;
      box-sizing: content-box;
      width: @carousel-dot-width;
      height: @carousel-dot-height;
      margin: 0 @carousel-dot-margin;
      padding: 0;
      text-align: center;
      text-indent: -999px;
      vertical-align: top;
      transition: all 0.5s;

      button {
        position: relative;
        display: block;
        width: 100%;
        height: @carousel-dot-height;
        padding: 0;
        color: transparent;
        font-size: 0;
        background: @component-background;
        border: 0;
        border-radius: 1px;
        outline: none;
        cursor: pointer;
        opacity: 0.3;
        transition: all 0.5s;

        &:hover,
        &:focus {
          opacity: 0.75;
        }

        &::after {
          position: absolute;
          top: -@carousel-dot-margin;
          right: -@carousel-dot-margin;
          bottom: -@carousel-dot-margin;
          left: -@carousel-dot-margin;
          content: '';
        }
      }

      &.slick-active {
        width: @carousel-dot-active-width;

        & button {
          background: @component-background;
          opacity: 1;
        }

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }
}

.@{ant-prefix}-carousel-vertical {
  .slick-dots {
    top: 50%;
    bottom: auto;
    flex-direction: column;
    width: @carousel-dot-height;
    height: auto;
    margin: 0;
    transform: translateY(-50%);

    &-left {
      right: auto;
      left: 12px;
    }

    &-right {
      right: 12px;
      left: auto;
    }

    li {
      width: @carousel-dot-height;
      height: @carousel-dot-width;
      margin: @carousel-dot-margin 0;
      vertical-align: baseline;

      button {
        width: @carousel-dot-height;
        height: @carousel-dot-width;
      }

      &.slick-active {
        width: @carousel-dot-height;
        height: @carousel-dot-active-width;

        button {
          width: @carousel-dot-height;
          height: @carousel-dot-active-width;
        }
      }
    }
  }
}

@import './rtl';

@root-entry-name: variable;