@import '../../style/themes/index';
@import '../../style/mixins/index';

@timeline-prefix-cls: ~'@{ant-prefix}-timeline';

.@{timeline-prefix-cls} {
  .reset-component();

  margin: 0;
  padding: 0;
  list-style: none;

  &-item {
    position: relative;
    margin: 0;
    padding-bottom: @timeline-item-padding-bottom;
    font-size: @font-size-base;
    list-style: none;

    &-tail {
      position: absolute;
      top: 10px;
      left: 4px;
      height: calc(100% - 10px);
      border-left: @timeline-width solid @timeline-color;
    }

    &-pending &-head {
      font-size: @font-size-sm;
      background-color: transparent;
    }

    &-pending &-tail {
      display: none;
    }

    &-head {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: @timeline-dot-bg;
      border: @timeline-dot-border-width solid transparent;
      border-radius: 100px;

      &-blue {
        color: @primary-color;
        border-color: @primary-color;
      }

      &-red {
        color: @error-color;
        border-color: @error-color;
      }

      &-green {
        color: @success-color;
        border-color: @success-color;
      }

      &-gray {
        color: @disabled-color;
        border-color: @disabled-color;
      }
    }

    &-head-custom {
      position: absolute;
      top: 5.5px;
      left: 5px;
      width: auto;
      height: auto;
      margin-top: 0;
      padding: 3px 1px;
      line-height: 1;
      text-align: center;
      border: 0;
      border-radius: 0;
      transform: translate(-50%, -50%);
    }

    &-content {
      position: relative;
      top: -(@font-size-base * @line-height-base - @font-size-base) + 1px;
      margin: 0 0 0 @margin-lg + 2px;
      word-break: break-word;
    }

    &-last {
      > .@{timeline-prefix-cls}-item-tail {
        display: none;
      }
      > .@{timeline-prefix-cls}-item-content {
        min-height: 48px;
      }
    }
  }

  &.@{timeline-prefix-cls}-alternate,
  &.@{timeline-prefix-cls}-right,
  &.@{timeline-prefix-cls}-label {
    .@{timeline-prefix-cls}-item {
      &-tail,
      &-head,
      &-head-custom {
        left: 50%;
      }

      &-head {
        margin-left: -4px;

        &-custom {
          margin-left: 1px;
        }
      }

      &-left {
        .@{timeline-prefix-cls}-item-content {
          left: calc(50% - 4px);
          width: calc(50% - 14px);
          text-align: left;
        }
      }

      &-right {
        .@{timeline-prefix-cls}-item-content {
          width: calc(50% - 12px);
          margin: 0;
          text-align: right;
        }
      }
    }
  }

  &.@{timeline-prefix-cls}-right {
    .@{timeline-prefix-cls}-item-right {
      .@{timeline-prefix-cls}-item-tail,
      .@{timeline-prefix-cls}-item-head,
      .@{timeline-prefix-cls}-item-head-custom {
        left: calc(100% - 4px - @timeline-width);
      }
      .@{timeline-prefix-cls}-item-content {
        width: calc(100% - 18px);
      }
    }
  }

  &&-pending &-item-last &-item-tail {
    display: block;
    height: calc(100% - 14px);
    border-left: 2px dotted @timeline-color;
  }

  &&-reverse &-item-last &-item-tail {
    display: none;
  }

  &&-reverse &-item-pending {
    .@{timeline-prefix-cls}-item-tail {
      top: 15px;
      display: block;
      height: calc(100% - 15px);
      border-left: 2px dotted @timeline-color;
    }
    .@{timeline-prefix-cls}-item-content {
      min-height: 48px;
    }
  }
  &.@{timeline-prefix-cls}-label {
    .@{timeline-prefix-cls}-item-label {
      position: absolute;
      top: -(@font-size-base * @line-height-base - @font-size-base) + 1px;
      width: calc(50% - 12px);
      text-align: right;
    }
    .@{timeline-prefix-cls}-item-right {
      .@{timeline-prefix-cls}-item-label {
        left: calc(50% + 14px);
        width: calc(50% - 14px);
        text-align: left;
      }
    }
  }
}

@import './rtl';

@root-entry-name: variable;