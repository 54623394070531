@import '../../style/themes/index';
@import '../../style/mixins/index';

@statistic-prefix-cls: ~'@{ant-prefix}-statistic';

.@{statistic-prefix-cls} {
  .reset-component();

  &-title {
    margin-bottom: @margin-xss;
    color: @text-color-secondary;
    font-size: @statistic-title-font-size;
  }

  &-skeleton {
    padding-top: @padding-md;
  }

  &-content {
    color: @heading-color;
    font-size: @statistic-content-font-size;
    font-family: @statistic-font-family;

    &-value {
      display: inline-block;
      direction: ltr;
    }

    &-prefix,
    &-suffix {
      display: inline-block;
    }

    &-prefix {
      margin-right: 4px;
    }

    &-suffix {
      margin-left: 4px;
    }
  }
}

@import './rtl';

@root-entry-name: variable;